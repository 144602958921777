@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700');

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f6f7f9;
    color: #5a6268;
}

.metka-item {
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 1px -1px, rgba(0, 0, 0, 0.14) 0 1px 1px 0, rgba(0, 0, 0, 0.12) 0 1px 3px 0;
    /*width: 165px;
    height: 100%;
    margin: 10px;*/
    border: 0;
}

.card-title {
    font-size: 12px;
    text-align: center;
    margin: 0;
    font-weight: normal;
}

.card-body {
    padding: 10px 10px;
}

.card {
    position: relative;
}

.album-qr-code {
    width: 20px;
}

.album-qr-code:hover {
    cursor: pointer;
}

.thead-light th {
    color: inherit !important;
    font-weight: 500;
}

.nav-pills .nav-link.active {
    color: #fff;
    background-color: #657c93;
}

.nav-pills .nav-item a {
    color: inherit;
}

/* Responsive layout - makes a two column-layout instead of four columns


.column {
    flex: 20%;
    max-width: 20%;
    padding: 0 10px;
}


@media screen and (max-width: 1200px) {
    .column {
        flex: 25%;
        max-width: 25%;
    }
}

@media screen and (max-width: 992px) {
    .column {
        flex: 33%;
        max-width: 33%;
    }
}

@media screen and (max-width: 800px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }
}
 */

/*
.card .editmetka {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    display: none;
    border-radius: 3px;
}

.card:hover .editmetka {
    display: block;
}

.card .editmetka > div {
    width: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -22px;
    margin-top: -22px;
}*/

@media screen and (max-width: 768px) {
    h1 {
        font-size: 1.5rem !important;
    }
}

.albums .active{
    margin-left: 250px;
}