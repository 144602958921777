.upload {
    height: 50px;
    border: 1px solid #c6c6c6;

    margin-bottom: 20px;
    cursor: pointer;
    background-color: #e6e9eb;
    text-align: center;
    line-height: 2.2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 5px;
    font-size: 16px;
    display: block;
}

.upload:hover {
    background-color: #d7d7d7;
}

.progress {
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
}

.uploadLink{
    cursor: pointer;
    color: #007bff;
}

.uploadLink:hover{
    text-decoration: underline;
}

