.flexFiles {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-bottom: -10px;
}

.flexFiles:after{
    content:'';
    flex-grow: 999;
    min-width: 20%;
    height: 0;
}