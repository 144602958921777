.sidebar {
    background-color: #071447;
    width: 80px;
    height: 100vh;
    display: block;
    position: fixed;
    color: white;
    z-index: 999;
    top: 0;
    left: 0;
}

.sidebar .items {
    margin-top: 45px;

}

.sidebar .items ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.nav-text-sidebar {
    text-align: center;
    height: 70px;
}

.nav-text-sidebar a {
    text-decoration: none;
    font-size: 11px;
    color: #e1e1e1;
}

.menu-bars {
    margin-top: 15px;
    margin-left: 25px;
}

.nav-menu {
    background-color: #071447;
    width: 240px;
    height: 100%;
    display: block;
    justify-content: center;
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 1000;
    box-shadow: 5px 0 9px 0 #B2B1B0;
}

.nav-menu.active {
    left: 0;
}

.nav-text {
    padding: 5px 0 5px 20px;
    list-style: none;
    height: 50px;

}

.nav-text a {
    text-decoration: none;
    font-size: 16px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #e1e1e1;
}

.nav-text:hover {
    background-color: #090931;
}

.nav-menu-items {
    width: 100%;
    padding: 0;
    margin-top: 20px;
}


