@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f6f7f9;
    color: #5a6268;
}

.metka-item {
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 1px -1px, rgba(0, 0, 0, 0.14) 0 1px 1px 0, rgba(0, 0, 0, 0.12) 0 1px 3px 0;
    /*width: 165px;
    height: 100%;
    margin: 10px;*/
    border: 0;
}

.card-title {
    font-size: 12px;
    text-align: center;
    margin: 0;
    font-weight: normal;
}

.card-body {
    padding: 10px 10px;
}

.card {
    position: relative;
}

.album-qr-code {
    width: 20px;
}

.album-qr-code:hover {
    cursor: pointer;
}

.thead-light th {
    color: inherit !important;
    font-weight: 500;
}

.nav-pills .nav-link.active {
    color: #fff;
    background-color: #657c93;
}

.nav-pills .nav-item a {
    color: inherit;
}

/* Responsive layout - makes a two column-layout instead of four columns


.column {
    flex: 20%;
    max-width: 20%;
    padding: 0 10px;
}


@media screen and (max-width: 1200px) {
    .column {
        flex: 25%;
        max-width: 25%;
    }
}

@media screen and (max-width: 992px) {
    .column {
        flex: 33%;
        max-width: 33%;
    }
}

@media screen and (max-width: 800px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }
}
 */

/*
.card .editmetka {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    display: none;
    border-radius: 3px;
}

.card:hover .editmetka {
    display: block;
}

.card .editmetka > div {
    width: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -22px;
    margin-top: -22px;
}*/

@media screen and (max-width: 768px) {
    h1 {
        font-size: 1.5rem !important;
    }
}

.albums .active{
    margin-left: 250px;
}
.AlbumItem_archiveImg__1SGnF{
    display: none;
    cursor: pointer;
    position: absolute;
}

.AlbumItem_albumitem__13kaX:hover .AlbumItem_archiveImg__1SGnF{
    display: block;
}
.MarkerAdd_upload__1sko5 {
    height: 50px;
    border: 1px solid #c6c6c6;

    margin-bottom: 20px;
    cursor: pointer;
    background-color: #e6e9eb;
    text-align: center;
    line-height: 2.2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 5px;
    font-size: 16px;
    display: block;
}

.MarkerAdd_upload__1sko5:hover {
    background-color: #d7d7d7;
}

.MarkerAdd_progress__3p51F {
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
}

.MarkerAdd_uploadLink__3hpKI{
    cursor: pointer;
    color: #007bff;
}

.MarkerAdd_uploadLink__3hpKI:hover{
    text-decoration: underline;
}


.MetkaItem_moderator__1Gwtz{
    padding: 20px;
    height: 200px;
    display: flex;
    justify-content: center; /*Центрирование по горизонтали*/
    align-items: center;
    text-align: center;
}

.MetkaItem_container_img__38Ugi{
    display: inline-block;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
}

.MetkaItem_sectionFile__2Fk7z {
    margin: 0 10px 10px 0;

}
.MarkersGrid_flexFiles__2v8uw {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-bottom: -10px;
}

.MarkersGrid_flexFiles__2v8uw:after{
    content:'';
    flex-grow: 999;
    min-width: 20%;
    height: 0;
}

.MarkersTable_class1__3MDAy {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.MarkersTable_deleteMetka__qXPsm {
    display: none;
    cursor: pointer;
    position: absolute;
    text-align: center;
}

.MarkersTable_actionTr__2oe8O:hover .MarkersTable_deleteMetka__qXPsm {
    display: block;

}

.MarkersTable_tableMarkers__2-hCY {
    white-space: nowrap;
}

.MarkersTable_styleThumb__24u3Z {
    background-color: white;
    display: block;
    position: absolute;
    height: 120px;
    z-index: 0;
    border: 1px solid #c1c1c1;
    //border-radius: 5px;
    margin-top: -130px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 3px 0, rgba(0, 0, 0, 0.14) 0 2px 2px 0;
    animation: MarkersTable_animate__yIiFQ .2s ease-in;
}

@keyframes MarkersTable_animate__yIiFQ{
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}



.Markers_view__224w3 .Markers_tableButton__BNrjZ {
    background: url(/static/media/table.27e378b2.svg) center no-repeat;
    background-size: 70%;
}

.Markers_view__224w3 .Markers_gridButton__3zHMG {
    background: url(/static/media/grid.1f2a1456.svg) center no-repeat;
    background-size: 70%;
}

.Markers_view__224w3 div {
    display: inline-block;
    height: 30px;
    width: 30px;
    vertical-align: middle;

}

.Markers_view__224w3 div:hover {
    cursor: pointer;
    background-color: #e9ecef;
    border-radius: 5px;
}

.Markers_view__224w3 div.Markers_active__3nw1o {
    background-color: #e9ecef;
    border-radius: 5px;
}

.sidebar {
    background-color: #071447;
    width: 80px;
    height: 100vh;
    display: block;
    position: fixed;
    color: white;
    z-index: 999;
    top: 0;
    left: 0;
}

.sidebar .items {
    margin-top: 45px;

}

.sidebar .items ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.nav-text-sidebar {
    text-align: center;
    height: 70px;
}

.nav-text-sidebar a {
    text-decoration: none;
    font-size: 11px;
    color: #e1e1e1;
}

.menu-bars {
    margin-top: 15px;
    margin-left: 25px;
}

.nav-menu {
    background-color: #071447;
    width: 240px;
    height: 100%;
    display: block;
    justify-content: center;
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 1000;
    box-shadow: 5px 0 9px 0 #B2B1B0;
}

.nav-menu.active {
    left: 0;
}

.nav-text {
    padding: 5px 0 5px 20px;
    list-style: none;
    height: 50px;

}

.nav-text a {
    text-decoration: none;
    font-size: 16px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #e1e1e1;
}

.nav-text:hover {
    background-color: #090931;
}

.nav-menu-items {
    width: 100%;
    padding: 0;
    margin-top: 20px;
}



