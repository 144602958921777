
.view .tableButton {
    background: url("../../icons/table.svg") center no-repeat;
    background-size: 70%;
}

.view .gridButton {
    background: url("../../icons/grid.svg") center no-repeat;
    background-size: 70%;
}

.view div {
    display: inline-block;
    height: 30px;
    width: 30px;
    vertical-align: middle;

}

.view div:hover {
    cursor: pointer;
    background-color: #e9ecef;
    border-radius: 5px;
}

.view div.active {
    background-color: #e9ecef;
    border-radius: 5px;
}
