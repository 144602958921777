.moderator{
    padding: 20px;
    height: 200px;
    display: flex;
    justify-content: center; /*Центрирование по горизонтали*/
    align-items: center;
    text-align: center;
}

.container_img{
    display: inline-block;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
}

.sectionFile {
    margin: 0 10px 10px 0;

}