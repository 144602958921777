
.class1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.deleteMetka {
    display: none;
    cursor: pointer;
    position: absolute;
    text-align: center;
}

.actionTr:hover .deleteMetka {
    display: block;

}

.tableMarkers {
    white-space: nowrap;
}

.styleThumb {
    background-color: white;
    display: block;
    position: absolute;
    height: 120px;
    z-index: 0;
    border: 1px solid #c1c1c1;
    //border-radius: 5px;
    margin-top: -130px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 3px 0, rgba(0, 0, 0, 0.14) 0 2px 2px 0;
    animation: animate .2s ease-in;
}

@keyframes animate{
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

